import { useState } from "react";
import { useNavigate } from "react-router-dom";

function TableRow({ editid, seteditid, item, index, onDelete }) {
  const [isEditing, setIsEditing] = useState(editid === item.id ? true : false);
  const [rowData, setRowData] = useState(item);
  const [editshow, seteditshow] = useState(false)

  function handleInputChange(event) {
    const { name, value } = event.target;
    setRowData({ ...rowData, [name]: value });
  }

  const handleSaveClick = async () => {
    seteditid(0)
    setIsEditing(false);
  };

  const handleDelete = () => {
    onDelete()
  };

  const sendprops = {
    // id: rowData.id,
    // name: rowData.name,
    // unit: rowData.unit,
    // value: rowData.value,
    // scope_type: rowData.scope_type,
    // category: rowData.category,
    // scope_data: rowData.scope_data

    id: rowData.id,
          name: rowData.name,
            category:  rowData.category,
            sub_category: rowData.sub_category,
            scope_type: rowData.scope_type,
            value: rowData.value,
            unit: rowData.unit,
            scope_data: rowData.scope_data




  };

  const navigate = useNavigate();
  const handleedit = (e) => {
    navigate('/editsubcatogry', { state: { props: sendprops } })
    seteditshow(true)
  }

  // if (isEditing) {
  //   return (
  //     <tr>
  //       <td>{index + 1}</td>
  //       <td>
  //         <input
  //           name="name"
  //           className="text-center"
  //           value={rowData?.name}
  //           onChange={handleInputChange}
  //         />
  //       </td>
  //       <td>
  //         <input
  //           name="value"
  //           className="text-center"
  //           value={rowData?.value}
  //           onChange={handleInputChange}
  //         />
  //       </td>
  //       <td>
  //         <input
  //           name="unit"
  //           className="text-center"
  //           value={rowData?.unit}
  //           onChange={handleInputChange}
  //         />
  //       </td>
  //       <td>
  //         <input
  //           name="unit"
  //           className="text-center"
  //           value={rowData?.unit}
  //           onChange={handleInputChange}
  //         />
  //       </td>
  //       <td>
  //         <input
  //           name="unit"
  //           className="text-center"
  //           value={rowData?.unit}
  //           onChange={handleInputChange}
  //         />
  //       </td>
  //       <td className=" text-center">
  //       <input
  //         name="unit"
  //         className="rowInput text-center"
  //         value={rowData?.unit}
  //         disabled={!editshow}
  //       />
  //          <input
  //         name="unit"
  //         className="rowInput text-center"
  //         value={rowData?.unit}
  //         disabled={!editshow}
  //       />
  //     </td>
  //       <td className="p-2 d-flex text-center align-items-center justify-content-center">
  //         <button className="edit-btn-table text-center btnsz" onClick={handleSaveClick}>
  //           Save
  //         </button>
  //         <button className="edit-btn-table ms-3 text-center btnsz" onClick={handleDelete}>
  //           <span className="px-2">Delete</span>
  //         </button>
  //       </td>
  //     </tr>
  //   );
  // }

  return (
    <tr>
      <td className="text-center">{index + 1}</td>
      <td className=" text-center">
        <input
          name="name"
          className="rowInput text-center"
          value={rowData?.name}
          disabled={!editshow}
        />
      </td>
      <td className="text-center">
        <input
          name="scope_type"
          className="rowInput text-center"
          value={`scope ${rowData?.scope_type}`}
          disabled={!editshow}
        />
      </td>
      {/* <td className=" text-center">
        <input
          name="parameter"
          className="rowInput text-center"
          value={rowData?.parameter}
          disabled={!editshow}
        />
      </td> */}
      {/* <td className=" text-center">
        <input
          name="unit"
          className="rowInput text-center"
          value={rowData?.unit}
          disabled={!editshow}
        />
      </td> */}
      <td className=" text-center">
        <input
          name="value"
          className="rowInput text-center"
          value={rowData?.value}
          disabled={!editshow}
        />
      </td>
      <td className=" text-center">
        <input
          name="unit"
          className="rowInput text-center"
          value={rowData?.unit}
          disabled={!editshow}
        />
      </td>
      <td className="text-center" style={{ color: rowData.status ? '#000' : '#E86F55' }}>
        <input
          name="status"
          className="rowInput text-center"
          value={rowData.status ? "Active" : "Inactive"}
          disabled={!editshow}
        />
      </td>
      <td className=" d-flex text-center align-items-center justify-content-center">
        {item.id ? (
          <>
            <button className="edit-btn-table text-center btnsz" onClick={() => handleedit(rowData.id)}>
              Edit
            </button>
            <button className="edit-btn-table flex items-center ms-3 text-center btnsz" onClick={handleDelete}>
              <span className="px-2">Delete</span>
            </button>
          </>
        ) : (
          <button className="edit-btn-table text-center" onClick={handleSaveClick}>
            Save
          </button>
        )}
      </td>
    </tr>
  );
}

export default TableRow;