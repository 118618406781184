import React, { useState ,useEffect} from 'react'
import InnerHeader from '../../Components/Common/InnerHeader'
import Backbtn from '../../Components/Common/Backbtn'
import 'react-phone-input-2/lib/style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import { ScopeEmmissionUpdate } from '../../Utils/services';
import { toast } from 'react-toastify';
import arrowdown from "../../Assets/image/icons/arrowdown.svg"
import { ScopeSettingGet } from '../../Utils/services';

const Editmeterial = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [scopedata, setScopedata] = useState([])
    const [formData, setFormData] = useState(location?.state?.props);
    // console.log(formData,'sourabhtt');
    const { register, handleSubmit, reset, formState: { errors }, } = useForm();
    // const [categoryOptions, setCategoryOptions] = useState([
    //     { value: "1", label: "Stationary",parent:1 },
    //     { value: "2", label: "Mobile Combustion" ,parent:1},
    //     { value: "3", label: "Chemicals and Gases Consumption",parent:1 },
    //     {value: "1", label: "Electricity",parent:2 },
    //     { value: "1 ", "label": "Purchased Raw Materials" ,parent:3 },
    //     { value: "2 ", "label": "Purchased Chemicals and Gases ",parent:3},
    //     { value: "3 ", "label": "Purchased Capital Goods ",parent:3},
    //     { value: "4 ", "label": "Supplier Fuel Consumption",parent:3 },
    //     { value: "5 ", "label": "Supplier Electricity Consumption ",parent:3},
    //     { value: "6 ", "label": "Buyer Fuel Consumption" ,parent:3},
    //     { value: "7 ", "label": "Buyer Electricity Consumption ",parent:3},
    //     { value: "8 ", "label": "Inbound Transport" ,parent:3},
    //     { value: "9", "label": "Outbound Transport" },
    //     { value: "10" , "label":" Business Travel ",parent:3},
    //     { value: "11" , "label":" Employee Commuting ",parent:3},
    //     { value: "12" , "label":" Warehouse Fuel Consumption ",parent:3},
    //     { value: "13", "label":" Warehouse Electricity Consumption ",parent:3},
    //     { value: "14", "label":" Supplier Leased Assests" ,parent:3},
    //     { value: "15", "label":" Buyer Leased Assets",parent:3 },
    //     { value: "16", "label":" Subsidiary Fuel Consumption ",parent:3},
    //     { value: "17", "label":" Subsidiary Electricity Consumption",parent:3 },
    //     { value: "18", "label":" Franchise Fuel Consumption" ,parent:3},
    //     { value: "19", "label":" Franchise Electricity Consumption ",parent:3},
    //     { value: "20", "label":" Waste Materials ",parent:3},
    //     { value: "21", "label":" Processing of Sold Products ",parent:3},
    //     { value: "22", "label":" Use of Sold Products" ,parent:3},
    //     { value: "23", "label":" End-of-Life Treatment of Sold Products ",parent:3}
    // ]);

    const onSubmit = async (evt) => {
        const data =
        {
            "category_name": formData.category_name,
            "parameter": formData.parameter,
            "scope_type": formData.scope_type,
            "status": formData.status,

        }
        const id = formData?.id
        try {
            const result = await ScopeEmmissionUpdate(data, id);
            reset({
                id: "",
                category_name: "",
                parameter: "",
                scope_type: "",
                status: "",
            });

            const Toast = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: '<span style="font-size: 14px">User update successfully</span>',
            })
            navigate('/scopecategory')
        }
        catch (error) {
            toast.error("An error occurred. Please try again.");
        }
    };
    const getScopedata = async () => {
        const result = await ScopeSettingGet();
        return result;
    }

    const onInputChange = async (evt) => {
        const { name, value } = evt.target;
        if (name === "scope_type") {
            const result = await getScopedata(value);

            let filterdata;
            if (value === "1") {
                filterdata = result?.res
                    .filter((item) => item.scope_type === "1")
                    .map((res) => ({
                        id: res.id,
                        label: res?.product,
                    }));
            } else if (value === "2") {
                filterdata = result?.res
                    .filter((item) => item.scope_type === "2")
                    .map((res) => ({
                        id: res.id,
                        label: res?.product,
                    }));
            } else if (value === "3") {
                filterdata = result?.res
                    .filter((item) => item.scope_type === "3")
                    .map((res) => ({
                        id: res.id,
                        label: res?.product,
                    }));
            }

            setScopedata(filterdata);
        }
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await ScopeSettingGet();
                
        
         const filterdata = result?.res
         .filter((item) => item.scope_type == location?.state?.props?.scope_type)
        .map((res) => ({
            id: res.id,
            label: res?.product,
        }));
              setScopedata(filterdata);
                
            } catch (error) {
                // Handle errors here
                console.error("Error fetching category data:", error);
            }
        };

        fetchData();
    }, [location?.state?.props?.scope_type]);

    return (
        <>
            <div>
                <InnerHeader />
            </div>
            <div className='mainWrapper'>
                <div className=''>
                    <Backbtn title="Edit" />
                </div>
                <form method="put" onSubmit={handleSubmit(onSubmit)} onChange={onInputChange} >

                    <div className="d-flex align-items-center justify-content-end pt-12" >
                        <button className="modelbottom mb-0 d-flex align-items-center justify-content-center " type="submit">Save</button>
                    </div>
                    <div className='pt-2'>
                        <div className="">
                            <div className="row ">
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12">
                                    <div className="">
                                        <p>Name</p>
                                        <input type="text" className="form-control mt-3" placeholder="Enter name here" name="category_name" value={formData?.category_name}  {...register("category_name", { required: true })} />
                                        {errors?.name?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div>


                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12 ">
                                    <div className="select-container">
                                        <p >Scope_type</p>
                                        <div className="select-wrapper">
                                            <select className="form-control mt-3 " name="scope_type" value={formData?.scope_type} {...register("scope_type", { required: true })}>
                                                <option value="1">scope 1</option>
                                                <option value="2">scope 2</option>
                                                <option value="3">scope 3</option>
                                            </select>
                                            <img src={arrowdown} alt="..." className="ml-1"></img>
                                        </div>
                                        {errors?.scope_type?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div>


                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12  mt-3 ">
                                    <div className="select-container">
                                        <p className="formtext">Parameter</p>
                                        <div className="select-wrapper">
                                            <select className="form-control mt-3" name="parameter" value={formData?.parameter}{...register("parameter", { required: true })}>

                                                <option value="">Select Parameter</option>
                                                {scopedata.map(option => (
                                                    <option key={option.id} value={option.id}>{option.label}</option>
                                                ))}
                                            </select>
                                            <img src={arrowdown} alt="..." className="ml-1"></img>
                                        </div>
                                        {errors?.parameter?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div>


                                {/* <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12  mt-3">
                                                    <div className="select-container">
                                                        <p className="">Parameter</p>
                                                        <div className="select-wrapper">
                                                            <select className="form-control mt-3 " name="parameter" value={formData?.parameter} {...register("parameter", { required: true })}>
                                                
                                                                {categoryOptions.filter((res)=>res.parent == formData.scope_type).map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                                            </select>
                                                            <img src={arrowdown} alt="..." className="ml-1"></img>
                                                        </div>
                                                        {errors?.parameter?.type === "required" && <p className="validationform">This field is required</p>}
                                                    </div>
                                                </div> */}


                                {/* <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12  mt-3">
                                    <div className="">
                                        <p>Parameter</p>
                                        <input type="text" className="form-control mt-3" placeholder="Enter parameter here" name="parameter" value={formData?.parameter}  {...register("parameter", { required: true })} />
                                        {errors?.parameter?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div> */}




                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12 mt-3">
                                    <div className="select-container">
                                        <p >Status</p>
                                        <div className="select-wrapper">
                                            <select className="form-control mt-3 " name="status" value={formData?.status} {...register("status", { required: true })}>
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </select>
                                            <img src={arrowdown} alt="..." className="ml-1"></img>
                                        </div>
                                        {errors?.status?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Editmeterial