import React from 'react'

const Settingdescription = ({ discrip }) => {

    return (
        <div className='inner_desc'>
            <p>
            You can add new fuel type and their units from here. Values of already added fuel types can also be changed.
            </p>
        </div>
    )
}

export default Settingdescription
