import React from 'react'
import SteamPlantTable from './ScopTwoPlantTable/Steam/SteamPlantTable'


const SteamPlant = () => {


    return (
        <div className='pt-2'>
            <p className='profile-texts'>Donnelly-Wisozk Die cast manufacturing unit</p>
       
          <SteamPlantTable/>

        </div>
    )
}

export default SteamPlant