import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Meterialscopeswitch = ({ merchant, collaterals,caregory }) => {
    const [active, setactive] = useState("")
    const navigate = useNavigate()
    return (
        <div className=" d-flex">

            <div className={` merchandise-libary ${merchant} `} onClick={() => {
                setactive('Approved'); navigate('/scope')
            }}>

                <h4 className={`  text-size ${active === 'Approved' ? 'active' : ''}`}>Scope</h4>

            </div>


            <div className={` ms-4 caregory-lab ${caregory}`} onClick={() => { setactive('Pending & Rejected'); navigate('/scopecategory') }}>

                <h4 className={` text-size ${active === 'Pending & Rejected ' ? 'active ' : ''}`}>Category</h4>
            </div>

            <div className={` ms-4 collaterals-lab ${collaterals}`} onClick={() => { setactive('Pending & Rejected'); navigate('/settings') }}>

                <h4 className={` text-size ${active === 'Pending & Rejected ' ? 'active ' : ''}`}>Material</h4>
            </div>

        </div>
    )
}

export default Meterialscopeswitch