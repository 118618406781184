import React, { useEffect, useState } from 'react'
import InnerHeader from '../../Components/Common/InnerHeader'
import Backbtn from '../../Components/Common/Backbtn'
import 'react-phone-input-2/lib/style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import { ScopeEmmissionUpdate2 } from '../../Utils/services';
import { CatgeoryListData, GetSubCatgeoryListAPI } from "../../Utils/services";
import { toast } from 'react-toastify';
import arrowdown from "../../Assets/image/icons/arrowdown.svg"
import { ScopeSettingGet } from '../../Utils/services';

const Editsubcatogry = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [scopedata, setScopedata] = useState([])
    const [showdata, setshowdata] = useState(true)
    const [scopeType, setScopeType] = useState()
    const [subscopeType, setSubscopeType] = useState()
    const [subcategory, setSubCategory] = useState([])
    const [formData, setFormData] = useState(location?.state?.props);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const form = new FormData();
    const [categoryOptions, setCategoryOptions] = useState([
 ]);


    const getCategory = async (data) => {
        const result = await CatgeoryListData(data);
        return result;
    }
    useEffect(() => {
        setScopeType(location?.state?.props?.scope_type)
    }, [location?.state?.props])
    useEffect(() => {
        setSubscopeType(location?.state?.props?.category)
    }, [location?.state?.props])



    const onSubmit = async (evt) => {
        const data =
        {
            "name": formData.name,
            "category": formData.category,
            "sub_category": formData.sub_category,
            "scope_type": formData.scope_type,
            "value": formData.value,
            "unit": formData.unit,
            "scope_data": formData.scope_data,
            "status": formData.status,

        }
        const id = formData?.id
        // console.log(data, "data")
        try {
            const result = await ScopeEmmissionUpdate2(data, id);
            reset({
                id: "",
                name: "",
                category: "",
                scope_type: "",
                value: "",
                unit: "",
                scope_data: "",
                status: "",
            });

            const Toast = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: '<span style="font-size: 14px">User update successfully</span>',
            })
            navigate('/settings')
        }
        catch (error) {
            toast.error("An error occurred. Please try again.");
        }
    };

    const getScopedata = async () => {
        const result = await ScopeSettingGet();
        return result;
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await ScopeSettingGet();
                
        
         const filterdata = result?.res
         .filter((item) => item.scope_type == location?.state?.props?.scope_type)
        .map((res) => ({
            id: res.id,
            label: res?.product,
        }));
              setScopedata(filterdata);
                
            } catch (error) {
                // Handle errors here
                console.error("Error fetching category data:", error);
            }
        };

        fetchData();
    }, [location?.state?.props?.scope_type]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getCategory(location?.state?.props?.scope_type);
                
                if (result?.res?.status == 200) {
                    
                    const filterdata = result?.res?.data.map((res) => ({
                        id: res.id,
                        label: res?.parameter,
                        value: res?.parameter,
                    }));
                    setCategoryOptions(filterdata);
                }
            } catch (error) {
                // Handle errors here
                console.error("Error fetching category data:", error);
            }
        };
    
        fetchData();
    }, [location?.state?.props?.scope_type]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await GetSubCatgeoryListAPI(location?.state?.props?.category);
                
                if (result?.res?.status == 200) {
                    
                    const filterdata = result?.res?.data.map((res) => ({
                        id: res.id,
                        label: res?.category_name,
                        value: res?.category_name,
                    }));
                    setSubCategory(filterdata);
                }
            } catch (error) {
                // Handle errors here
                console.error("Error fetching category data:", error);
            }
        };
    
        fetchData();
    }, [location?.state?.props?.category]);
    
    const onInputChange = async (evt) => {
        const { name, value } = evt.target;
    
        // Handle scope_type change
        if (name === "scope_type") {
            if (value === "1" || value === "2") {
                setshowdata(false);
            } else if (value === '3') {
                setshowdata(true);
            }
        }
    
        // Fetch subcategory data if category changes
        if (name === "category") {
            const result = await GetSubCatgeoryListAPI(value);
            if (result?.res?.status === 200) {
                const filterdata = result?.res?.data.map((res) => ({
                    id: res.id,
                    label: res?.category_name,
                    value: res?.category_name,
                }));
                setSubCategory(filterdata);
            }
        }
    
        // Filter and set scopedata based on scope_type
        if (name === "scope_type") {
        const result = await getScopedata(value);
        let filterdata;
        if (value === "1" || value === "2" || value === "3") {
            filterdata = result?.res
                .filter((item) => item.scope_type === value)
                .map((res) => ({
                    id: res.id,
                    label: res?.product,
                }));
            setScopedata(filterdata);
        }
        }
        // Update form data
        setFormData({ ...formData, [name]: value });
    };
    

    useEffect(() => {
        // Set showdata based on formData.scope_type when the page loads
        if (formData.scope_type === 1 || formData.scope_type === 2) {
            setshowdata(false);
        } else if (formData.scope_type === 3) {
            setshowdata(true);
        }
    }, [formData.scope_type]);
    
    

    return (
        <>
            <div>
                <InnerHeader />
            </div>
            <div className='mainWrapper'>
                <div className=''>
                    <Backbtn title="Edit" />
                </div>
                <form method="put" onSubmit={handleSubmit(onSubmit)} onChange={onInputChange} >

                    <div className="d-flex align-items-center justify-content-end pt-12" >
                        <button className="modelbottom mb-0 d-flex align-items-center justify-content-center " type="submit">Save</button>
                    </div>
                    <div className='pt-2'>
                        <div className="">
                            <div className="row ">
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12">
                                    <div className="">
                                        <p>Name</p>
                                        <input type="text" className="form-control mt-3" placeholder="Enter name here" name="name" value={formData?.name}  {...register("name", { required: true })} />
                                        {errors?.name?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div>


                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12 ">
                                    <div className="select-container">
                                        <p >Scope_type</p>
                                        <div className="select-wrapper">
                                            <select className="form-control mt-3 " name="scope_type" value={formData?.scope_type} {...register("scope_type", { required: true })}>
                                                <option value="1">scope 1</option>
                                                <option value="2">scope 2</option>
                                                <option value="3">scope 3</option>
                                            </select>
                                            <img src={arrowdown} alt="..." className="ml-1"></img>
                                        </div>
                                        {errors?.scope_type?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div>

                             

                                {/* <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12  mt-3 ">
                                    <div className="select-container">
                                        <p className="formtext">Category</p>
                                        <div className="select-wrapper">
                                            <select className="form-control mt-3" name="category" value={formData?.category} {...register("category")}>

                                                <option value="">Select Category</option>
                                                {categoryOptions.map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <img src={arrowdown} alt="..." className="ml-1"></img>
                                        </div>
                                        {errors?.category?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div> */}


                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12  mt-3 ">
                                    <div className="select-container">
                                        <p className="formtext">Category</p>
                                        <div className="select-wrapper">
                                            <select className="form-control mt-3" name="category" value={formData?.category}{...register("category", { required: true })}>

                                                <option value="">Select Category</option>
                                                {scopedata.map(option => (
                                                    <option key={option.id} value={option.id}>{option.label}</option>
                                                ))}
                                            </select>
                                            <img src={arrowdown} alt="..." className="ml-1"></img>
                                        </div>
                                        {errors?.category?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div>


                                {showdata ?
                                    <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12  mt-3 ">
                                        <div className="select-container">
                                            <p className="formtext">Sub Category</p>
                                            <div className="select-wrapper">
                                                <select className="form-control mt-3" name="sub_category" value={formData?.sub_category} {...register("sub_category",)}>

                                                    <option value="">Select Category</option>
                                                    {subcategory?.map(option => (
                                                        <option key={option.value} value={option.value}>{option.label}</option>
                                                    ))}
                                                </select>
                                                <img src={arrowdown} alt="..." className="ml-1"></img>
                                            </div>

                                        </div>
                                    </div>
                                    : ""}


                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12 mt-3">
                                    <div className="">
                                        <p>Scope_data</p>
                                        <input type="text" className="form-control mt-3" placeholder="Enter scopedata here" name="scope_data" value={formData?.scope_data}  {...register("scope_data", { required: true })} />
                                        {errors?.scope_data?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div>


                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12 mt-3">
                                                    <div className="select-container">
                                                        <p >Status</p>
                                                        <div className="select-wrapper">
                                                            <select className="form-control mt-3 " name="status"  value={formData?.status} {...register("status", { required: true })}>
                                                                <option value="true">Active</option>
                                                                <option value="false">Inactive</option>
                                                            </select>
                                                            <img src={arrowdown} alt="..." className="ml-1"></img>
                                                        </div>
                                                        {errors?.status?.type === "required" && <p className="validationform">This field is required</p>}
                                                    </div>
                                                </div>

                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12 mt-3">
                                    <div>
                                        <p>Value</p>
                                        <input
                                            className="form-control mt-3"
                                            type="text"
                                            placeholder="Enter value here"
                                            name="value"
                                            value={formData?.value}
                                            {...register("value", { required: true })}
                                        />
                                        {errors?.value?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-12 mt-3">
                                    <div>
                                        <p>Unit</p>
                                        <input
                                            className="form-control mt-3"
                                            type="text"
                                            placeholder="Enter unit"
                                            name="unit"
                                            value={formData?.unit}
                                            {...register("unit", { required: true })}
                                        />
                                        {errors?.unit?.type === "required" && <p className="validationform">This field is required</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Editsubcatogry