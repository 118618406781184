import React from 'react'
import CoolingPlanttable from './ScopTwoPlantTable/cooling/CoolingPlanttable'
const CoolingPlant = () => {


    return (
        <div className='pt-2'>
            <p className='profile-texts'>Donnelly-Wisozk Die cast manufacturing unit</p>
       
            <CoolingPlanttable/>
  
        </div>
    )
}

export default CoolingPlant