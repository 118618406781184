import React, { useEffect, useMemo, useState } from 'react';
import Settingdescription from '../../Components/Common/Settingdescription';
import Materialscopeswitch from '../../Components/Common/Materialscopeswitch';
import TableRowcategory from '../../Components/Common/TableRowcategory';
import { saveAs } from 'file-saver';
import sample from "../../../src/Assets/file/sample.xlsx"
import { Form } from 'react-bootstrap';
import Dropdown from "react-bootstrap/Dropdown";
import { DataScopeEmission12, DeleteScope, EmissionScope } from '../../Utils/services';
import { toast } from 'react-toastify';
import arrowdown from "../../Assets/image/icons/arrowdown.svg"
import Swal from 'sweetalert2';
import MaterialCertificate from '../../Components/commoncomonent/MaterialCertificate';
import Commontitle from '../../Components/Common/Commontitle';
import Categorysetting from '../../Components/commoncomonent/Categorysetting';

function ScopeCategory() {
  const [open, setOpen] = React.useState(false);
  const [check, setcheck] = useState({
    Scope1: false,
    Scope2: false,
    Scope3: false,
  });
  const [checkstatus, setcheckstatus] = useState({
    Truedta: false,
    Falsedta: false,
  });


  
const [turn, setturn] = useState(false);
  const [file, setfile] = useState(null);
  const [editid, seteditid] = useState(0)
  const [data, setData] = useState([]);
  const [FilteredData,setFilteredData]=useState([])
  const [webid, setwebid] = useState()

  const request = async () => {
    const result = await DataScopeEmission12()
   
    setData(result.res.data)
    setFilteredData(result.res.data)
    console.log(result.res.data,'rathirathi');
  }
  useEffect(() => {
    request()
  }, [])
    
    const handleDeleteRow = async (id) => {
      try {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            const deleteResult = await DeleteScope(id);
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
            request();
          }
        });
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      }
    };


    const [selected, setSelected] = useState([]);
    const handleCheckboxChange = (value, checked) => {
      if (checked) {
        setSelected([...selected, value]);
      } else {
        setSelected(selected.filter(v => v !== value));
      }
    };
    
  
const handleclicked = (isAdminValue) => {
   
  setcheck({
    Scope1: isAdminValue == 1 ? !check.Scope1 : check.Scope1,
    Scope2: isAdminValue == 2 ? !check.Scope2 : check.Scope2,
    Scope3: isAdminValue == 3 ? !check.Scope3 : check.Scope3,
  });
};

const handleclickedstatus = (isAdminValue) => {
   
  setcheckstatus({
    Truedta: isAdminValue == true ? !checkstatus.Truedta : checkstatus.Truedta,
    Falsedta: isAdminValue == false ? !checkstatus.Falsedta : checkstatus.Falsedta,
  });
}

    const filter = () => {
      
      const arr = [];
  
      if (check.Scope1) {

        FilteredData.filter((item) => {
          if (item.scope_type == 1) {


            arr.push(item);
          }
        });
      }
      if (check.Scope2) {
        FilteredData.filter((item) => {
          if (item.scope_type == 2) {
            arr.push(item);
          }
        });
      }
      if (check.Scope3) {
        FilteredData.filter((item) => {
          if (item.scope_type == 3) {
            arr.push(item);
          }
        });
      }
   


      setData(arr);
      if (!check.Scope1 && !check.Scope2 && !check.Scope3 ) {
        setData(FilteredData);
      }
   
    
    };



    const filter2 = () => {
      
      const arr = [];
  
     
      if (checkstatus.Truedta) {

        FilteredData.filter((item) => {
          
          if (item.status == true) {


            arr.push(item);
          }
        });
      }
      if (checkstatus.Falsedta) {
        FilteredData.filter((item) => {
          if (item.status == false) {
            arr.push(item);
          }
        });
      }


      setData(arr);
      if ( !checkstatus.Truedta && !checkstatus.Falsedta) {
        setData(FilteredData);
      }
   
    };

    // const filter = () => {
    //   const arr = [];
    //   setData(arr);
    //   if (!check.Truedta && !check.Falsedta ) {
    //     setData(FilteredData);
    //   }
    // };

    useEffect(() => {
      filter();
    }, [check]);

    useEffect(() => {
      filter2();
    }, [checkstatus]);

    // useEffect(() => {
    //   filterAdminScope1();
    // }, [checkstatus]);

  const handledownload = () => {
    saveAs(sample, 'Sample.xlx');  
  } 

  const filechange = (e) => {
    setfile(e.target.files[0])
  }
  const handlefile = async (values) => {
    if (file != null) {
      try {
        const formData = new FormData();
        if (file) {
          formData.append("file", file);
          // console.log(formData);
          const result = await EmissionScope(formData);
          toast.success(result.message);
        }
      }
      catch (error) {
        toast.error(error.message);
      }
    }
    else {
      toast.error("file is required")
    } 
  }

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    const filterArray = FilteredData.filter((e) => {
      return e.category_name.toLowerCase().includes(searchValue.toLowerCase()) ;
    });
    if (searchValue === "") {
      setData(data);
      request();
    } else {
      setData(filterArray);
    }
  };
  return (
    <div className="mainWrapper pt-5">
      <div className='martop'>
      <div className="companies">
        {/* <h1>Settings</h1> */}
      <Commontitle title={'Settings'}/>

      </div>
      <div className="productswitch mt-3 d-flex align-items-center justify-content-between">
        <Materialscopeswitch
          caregory="productactive"
        />

      </div>
      <div className="mt-3">
        <Settingdescription discrip="" />
      </div>
      </div>
      <div className="mt-3 dropupper" >
        <div className="d-flex align-items-center justify-content-between butnsrch">
        <div className='searchInput searchinginput'>
              <input type="text"  onChange={handleSearch} />
            </div>

      <div className='flex justify-end buttonsd'>
{/* <button className='d-flex align-items-center uploaddrop' style={{  border: '1px solid #1CAF59' ,padding:'8px 17px',marginRight:'8px'}} onClick={() => { setturn(true) }} >
            <p  style={{color:'#158D47',fontSize:'14px',fontWeight:500}}>Upload from CSV</p>
</button> */}

          <button
            className="table-pdf-btn px-3 py-2 flex items-center me-3 editsttingbutton"
            onClick={() => setOpen(true)}
            style={{background:'#1CAF59',color:'white'}}
          >
            <span className="px-2">Add Category</span>
          </button>
          </div>
        </div>
        <div className="plant-table mt-3 dataheig" style={{overflowY:'scroll',height:'calc(100vh - 380px)'}} >
          <table className="w-100  table-theme-1 " style={{ overflowX: 'scroll',overflowY:'scroll'}}>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Category Name</th>
                <th>
    
                <Dropdown className='show dropdown' style={{display:'inline-block'}}>
                    <Dropdown.Toggle className="d-flex align-items-center justify-content-between orderbar px-3 hoverbtn ">
                     Scope
                      <img src={arrowdown} alt="..." className="ml-1"></img>

                    </Dropdown.Toggle>
                    <Dropdown.Menu className="sortheight">
                      <Form.Check
                        type="checkbox"
                        label="Scope 1"
                        value="1"
                        className="form-checked checkbox-green"
                        checked={selected.includes(1)}
                        onChange={e => handleCheckboxChange(1, e.target.checked)}
                        onClick={() => handleclicked(1)} 
                      />
                      <Form.Check
                        type="checkbox"
                        label="Scope 2"
                        value="2"
                        className="form-checked checkbox-green"
                        checked={selected.includes(2)}
                        onChange={e => handleCheckboxChange(2, e.target.checked)}
                        onClick={() => handleclicked(2)} 
                      />
                      <Form.Check
                        type="checkbox"
                        label="Scope 3"
                        value="3"
                        className="form-checked checkbox-green"
                        checked={selected.includes(3)}
                        onChange={e => handleCheckboxChange(3, e.target.checked)}
                        onClick={() => handleclicked(3)} 
                      />
                    </Dropdown.Menu>
                  </Dropdown>
          
                  </th>
                {/* <th>Parameter</th> */}
                <th>
                <Dropdown className='show dropdown' style={{display:'inline-block'}}>
                    <Dropdown.Toggle className="d-flex align-items-center justify-content-between orderbar px-3 hoverbtn ">
                    Status
                      <img src={arrowdown} alt="..." className="ml-1"></img>

                    </Dropdown.Toggle>
                    <Dropdown.Menu className="sortheight">
                      <Form.Check
                        type="checkbox"
                        label="Active"
                        value="true"
                        className="form-checked checkbox-green"
                        checked={selected.includes(true)}
                        onChange={e => handleCheckboxChange(true, e.target.checked)}
                        onClick={() => handleclickedstatus(true)} 
                      />
                      <Form.Check
                        type="checkbox"
                        label="Inactive"
                        value="false"
                        className="form-checked checkbox-green"
                        checked={selected.includes(false)}
                        onChange={e => handleCheckboxChange(false, e.target.checked)}
                        onClick={() => handleclickedstatus(false)} 
                      />
                     
                    </Dropdown.Menu>
                  </Dropdown>

                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <TableRowcategory
                  editid={editid}
                  seteditid={seteditid}
                  key={item.id}
                  index={index}
                  item={item}
                  onDelete={()=>handleDeleteRow(item.id)}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Categorysetting setOpen={setOpen} open={open} request={request}/>
<MaterialCertificate setOpen={setturn} open={turn} />

    </div>
  );
}
export default ScopeCategory;