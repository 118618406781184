import React, { useEffect, useState, useContext } from "react";
import { filterAdminScope2 } from '../../../../Utils/services';
import AuthContext from '../../../../Utils/auth-context';

function CoolingPlanttable() {
    const [rowData, setRowData] = useState([]);
    const authCtx = useContext(AuthContext);
    const id = localStorage.getItem("setid");
    const [localdate1, setLocalDate1] = useState(localStorage.getItem("form"));


    useEffect(() => {
        const fetchData = async () => {
            if (localdate1) {
                const data = {
                    "assessment_year": localdate1,
                    "category": 'Cooling',
                    "user_id": id,
                    "plant_type": authCtx?.plant
                };

                try {
                    const result = await filterAdminScope2(data);
                    setRowData(result?.data || []);
                } catch (error) {
                    console.error("Error fetching data:", error);
                    setRowData([]);
                }
            }
        };

        fetchData();

        // Update localdate1 when localStorage changes
        const handleStorageChange = () => {
            setLocalDate1(localStorage.getItem("form"));
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [authCtx?.plant, id]); 

    // const viewdata = async () => {
    //     if(localdate1){

    //     const data = {
    //         "assessment_year":localdate1,
    //         "category": 'Cooling',
    //         "user_id": id,
    //         "plant_type": authCtx?.plant
    //     };

    //     try {
    //         const result = await filterAdminScope2(data);
    //         setRowData(result?.data || []);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //         setRowData([]);
    //     }
    // }
    // };

    // useEffect(() => {
    //     viewdata();
    // }, [authCtx?.plant,localdate1]);

    return (
        <>
            <div className='plant-table pt-20'>
                <table>
                    <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Month</th>
                        <th>Source of Cooling</th>
                        <th>Steam Consumed</th>
                       
                    </tr>
                    </thead>    
                    <tbody>
                        {rowData.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.created_on}</td>
                                <td>{item?.source_name}</td>
                                <td>
                                    {item?.consumed_power} <span style={{ color: '#A2A2A2', fontSize: '14px' }}>{item?.unit}</span>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div></>

    );
}

export default CoolingPlanttable;