export const dumydata = {
  "Purchased Chemicals and Gases": "purchased-chemicals-and-gases",
  "Goods and Services": "goods-and-services",
  "Inbound Transport": "inbound-transport",
  "Supplier Electricity Consumption": "suppilerelectricityconsumption",
  "Buyer Fuel Consumption": "buyer-fuel-consumption",
  "Buyer Electricity Consumptions": "buyerElectricity-consumption",
  "Outbound Transport": "outbound-transport",
  "Business Travel": "business-travel",
  "Employee Commuting": "employee-commuting",
  "Warehouse Fuel Consumption": "warehouse-fuel-consumption",
  "Warehouse Electricity Consumption": "warehouseelectricityconsumption",
  "Supplier Leased Assests": "supplierleased-or-rented-assets",
  "Buyer Leased Assets": "buyerleased-or-rentedassets",
  "Waste Materials": "waste-materials",
  "Subsidiary Fuel Consumption": "subsidiary-fuel-consumption",
  "Subsidiary Electricity Consumptio": "subsidiaryelectricityconsumption",
  "Use of Sold Products": "sold-product",
  "Franchise Electricity Consumption": "franchiseelectricityconsumption",
  "Purchased Raw Materials": "purchased-row-materials",
  "Franchise Fuel Consumption": "franchisefuelconsumption",
  "Supplier Fuel Consumption":"Supplier Fuel Consumption",
"Purchased Capital Goods" : "purchased-capital-goods",
"End-of-Life Treatment of Sold Products":"end-of-life-treatment-of-sold-products",
"Processing of Sold Products":"processing-of-sold-products"
};

